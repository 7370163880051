import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import DeliveryColumn from 'Features/Quotes/Components/QuoteRow/DeliveryColumn';
import PickupColumn from 'Features/Quotes/Components/QuoteRow/PickupColumn';
import Progress from 'Features/Quotes/Components/QuoteRow/Progress';
import TransitColumn from 'Features/Quotes/Components/QuoteRow/TransitColumn';
import { isDomesticCanada } from 'Helpers/isDomesticCanada';
import {
    Broker,
    Contact,
    LineItem,
    Location,
    ShipmentState,
    TrackingLine,
} from 'generated-openapi-client';
import { Currency } from 'generated-openapi-client/models/Currency';
import { EquipmentType } from 'generated-openapi-client/models/EquipmentType';
import moment from 'moment';
import styled from 'styled-components/macro';
import ViewShipmentSection from './ViewShipmentSection';

// Like Quote object, but with fewer required fields
export interface QuoteLike {
    carrierIdentifier: string;
    serviceDisplayName: string;
    transitBusinessDays?: number;
    latestTransitBusinessDays?: number;
}

interface MostRecentTrackingLineProps {
    mostRecentTrackingLine: TrackingLine | undefined;
}

const LatestUpdate = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: -4px;
`;

const LatestMessage = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);
`;

const LatestTime = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 12px;
    color: var(--freightsimple-color-normal-text);
`;

export function MostRecentTrackingLine(props: MostRecentTrackingLineProps) {
    const { mostRecentTrackingLine } = props;

    if (mostRecentTrackingLine === undefined) {
        return <></>;
    }

    function formatDate(date: Date) {
        return moment(date).format('dddd, MMMM Do YYYY, h:mma');
    }

    return (
        <Stack align="left">
            <LatestUpdate>Latest Update</LatestUpdate>
            <LatestMessage>{mostRecentTrackingLine.message}</LatestMessage>
            <LatestTime>
                {formatDate(mostRecentTrackingLine.timestamp)}
            </LatestTime>
            <Spacer height={24} />
        </Stack>
    );
}

interface DetailsSectionProps {
    pickupDate: moment.Moment;
    pickupDeadline?: string | undefined;
    deliveryHours: string | undefined;
    pickupLocation: Location | AddLocationLocationType;
    deliveryLocation: Location | AddLocationLocationType;
    deliveryDeadline: string | undefined;
    pickupContact: Contact | undefined;
    deliveryContact: Contact | undefined;
    lineItems: Array<LineItem | AddLineItemType>;
    quote: QuoteLike; // Might not have all the info when rendering for tracking
    shipmentState: ShipmentState;
    actualDeliveryDate: string | undefined;
    actualDeliveryTime: string | undefined;
    proNumber: string | undefined;
    expectedDeliveryDate: string | undefined;
    latestExpectedDeliveryDate: string | undefined;
    pickupReferenceNumber: string | undefined;
    deliveryReferenceNumber: string | undefined;
    pickupBoothNumber: string | undefined;
    deliveryBoothNumber: string | undefined;
    broker: Broker | undefined;
    mostRecentTrackingLine: TrackingLine | undefined;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: Currency;
    equipmentType: EquipmentType | undefined;
    linearFeet: number;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    onChangePickupDate: () => Promise<void>;
    onChangeBroker: () => Promise<void>;
    onModifyPickupAddress: () => Promise<void>;
    onModifyDeliveryAddress: () => Promise<void>;
    onModifyPickupContact: () => Promise<void>;
    onModifyDeliveryContact: () => Promise<void>;
    onModifyPickupReferenceAndNotes: () => Promise<void>;
    onModifyDeliveryReferenceAndNotes: () => Promise<void>;
}
function DetailsSection(props: DetailsSectionProps) {
    const {
        pickupDate,
        deliveryHours,
        pickupLocation,
        pickupBoothNumber,
        deliveryBoothNumber,
        deliveryLocation,
        pickupContact,
        deliveryContact,
        deliveryDeadline,
        lineItems,
        quote,
        shipmentState,
        actualDeliveryDate,
        actualDeliveryTime,
        expectedDeliveryDate,
        latestExpectedDeliveryDate,
        proNumber,
        pickupReferenceNumber,
        deliveryReferenceNumber,
        broker,
        onChangePickupDate,
        mostRecentTrackingLine,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
        equipmentType,
        exclusiveUse,
        tarpRequired,
        linearFeet,
        pickupDeadline,
    } = props;

    function checkPermissable(
        f: () => Promise<void>,
        allowIfInTransit: boolean
    ): (() => Promise<void>) | undefined {
        if (allowIfInTransit) {
            return shipmentState === ShipmentState.Cancelled ||
                shipmentState === ShipmentState.Delivered ||
                shipmentState === ShipmentState.Lost
                ? undefined
                : f;
        } else {
            return shipmentState === ShipmentState.InTransit ||
                shipmentState === ShipmentState.Cancelled ||
                shipmentState === ShipmentState.Delivered ||
                shipmentState === ShipmentState.Lost
                ? undefined
                : f;
        }
    }

    const onChangeBroker = checkPermissable(props.onChangeBroker, true);
    const onModifyPickupContact = checkPermissable(
        props.onModifyPickupContact,
        false
    );

    const onModifyPickupAddress = checkPermissable(
        props.onModifyPickupAddress,
        false
    );

    const onModifyPickupReferenceAndNotes = checkPermissable(
        props.onModifyPickupReferenceAndNotes,
        false
    );

    const onModifyDeliveryContact = checkPermissable(
        props.onModifyDeliveryContact,
        true
    );

    const onModifyDeliveryAddress = checkPermissable(
        props.onModifyDeliveryAddress,
        true
    );

    const onModifyDeliveryReferenceAndNotes = checkPermissable(
        props.onModifyDeliveryReferenceAndNotes,
        true
    );

    return (
        <ViewShipmentSection title="Details" id="details-section">
            <MostRecentTrackingLine
                mostRecentTrackingLine={mostRecentTrackingLine}
            />
            <Progress shipmentState={shipmentState} />
            <Spacer height={16}></Spacer>
            <HorizontalStack width="100%" verticalAlign="top">
                <PickupColumn
                    pickupDeadline={pickupDeadline}
                    lineItems={lineItems}
                    pickupDate={pickupDate}
                    pickupLocation={pickupLocation}
                    pickupContact={pickupContact}
                    shipmentState={shipmentState}
                    equipmentType={equipmentType}
                    exclusiveUseNeeded={exclusiveUse}
                    tarpRequired={tarpRequired}
                    linearFeet={linearFeet}
                    quote={quote}
                    pickupReferenceNumber={pickupReferenceNumber}
                    onChangePickupDate={onChangePickupDate}
                    isDomesticCanada={isDomesticCanada(
                        pickupLocation,
                        deliveryLocation
                    )}
                    onModifyPickupAddress={onModifyPickupAddress}
                    onModifyPickupContact={onModifyPickupContact}
                    onModifyPickupReferenceAndNotes={
                        onModifyPickupReferenceAndNotes
                    }
                    showNotesSection={true}
                    pickupBoothNumber={pickupBoothNumber}
                />
                <TransitColumn
                    quote={quote}
                    shipmentState={shipmentState}
                    proNumber={proNumber}
                    broker={broker}
                    onChangeBroker={onChangeBroker}
                    addInsuranceToShipment={addInsuranceToShipment}
                    insuranceAmount={insuranceAmount}
                    insuranceCurrency={insuranceCurrency}
                />
                <DeliveryColumn
                    quote={quote}
                    deliveryHours={deliveryHours}
                    deliveryLocation={deliveryLocation}
                    deliveryContact={deliveryContact}
                    deliveryDeadline={deliveryDeadline}
                    shipmentState={shipmentState}
                    actualDeliveryDate={actualDeliveryDate}
                    actualDeliveryTime={actualDeliveryTime}
                    expectedDeliveryDate={expectedDeliveryDate}
                    latestExpectedDeliveryDate={latestExpectedDeliveryDate}
                    deliveryReferenceNumber={deliveryReferenceNumber}
                    onModifyDeliveryAddress={onModifyDeliveryAddress}
                    onModifyDeliveryContact={onModifyDeliveryContact}
                    onModifyDeliveryReferenceAndNotes={
                        onModifyDeliveryReferenceAndNotes
                    }
                    showNotesSection={true}
                    deliveryBoothNumber={deliveryBoothNumber}
                />
            </HorizontalStack>
        </ViewShipmentSection>
    );
}
export default DetailsSection;
