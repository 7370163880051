import SlideDown from 'Cargo/Animations/SlideDown';
import MiniMap from 'Cargo/Controls/MiniMap';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Label } from 'Cargo/Text/Text';
import { getAccessorials } from 'Data/AccessorialTypes';
import LocationDropdown from 'Features/BookShipment/Components/LocationDetails/LocationDropdown';
import LocationTypeQuestionBubble from 'Features/BookShipment/Components/LocationDetails/LocationTypeQuestionBubble';
import AccessorialsInput from 'Features/Locations/Components/AccessorialsInput';
import PostalCodeInput, {
    PostalCodeChangeEvent,
} from 'Features/Locations/Components/PostalCodeInput';
import {
    AddLocationAddressType,
    AddLocationLocationType,
} from 'Features/Locations/Types/locationTypes';
import { LocationErrors } from 'Features/Locations/Validators/errorMessagesForLocation';
import { startsWithNumber } from 'Helpers/formatPostalCode';
import { Accessorials } from 'apis';
import {
    LatitudeLongitude,
    Location,
    LocationContext,
    LocationType,
} from 'generated-openapi-client';

interface LocationDetailsProps {
    locationErrorMessages: LocationErrors;
    locationWarningMessages: LocationErrors;
    forceValidation: boolean;
    address: AddLocationAddressType;
    location: AddLocationLocationType | Location;
    onPostalCodeChange: (
        _: PostalCodeChangeEvent,
        groceryWarehouseName: string | undefined
    ) => void;
    showMap: boolean;
    locationContext: LocationContext;
    latitudeLongitude: LatitudeLongitude | undefined;
    setLocationType: (_: LocationType) => void;
    accessorials: Accessorials;
    setSchedulingAccessorials: (_: Accessorials) => void;
    setLogisticsAccessorials: (_: Accessorials) => void;
    disablePostalCodeEntry: boolean;
    groceryWarehouseName: string | undefined;
}

function LocationDetails(props: LocationDetailsProps) {
    const {
        locationErrorMessages,
        locationWarningMessages,
        forceValidation,
        address,
        onPostalCodeChange,
        showMap,
        location,
        locationContext,
        setLocationType,
        accessorials,
        setSchedulingAccessorials,
        setLogisticsAccessorials,
        groceryWarehouseName,
    } = props;

    function displayNameForLocationContext(
        locationContext: LocationContext | undefined
    ) {
        if (locationContext === LocationContext.Pickup) {
            return 'Pickup';
        }

        if (locationContext === LocationContext.Delivery) {
            return 'Delivery';
        }

        return undefined;
    }

    const displayName = displayNameForLocationContext(props.locationContext);
    const displayNameWithSpace = (displayName || '') + ' ';

    const latitude = props.latitudeLongitude?.latitude;
    const longitude = props.latitudeLongitude?.longitude;

    function labelForPostalCode() {
        const postalCode = address.postalCode;

        if (postalCode === undefined || postalCode === '') {
            return `${displayNameWithSpace}Postal Code / Zip Code`;
        }

        if (!startsWithNumber(postalCode)) {
            return `${displayNameWithSpace}Postal Code`;
        } else {
            return `${displayNameWithSpace}Zip Code`;
        }
    }

    const postalCodeLabel = labelForPostalCode();

    const schedulingAccessorials = getAccessorials(
        locationContext,
        location.locationType,
        'SCHEDULING_'
    );

    const selectedSchedulingAccessorials =
        accessorials.filter((a) => a.startsWith('SCHEDULING_')) || [];

    console.log(`!!!!! selectedSchedulingAccessorials`, {
        selectedSchedulingAccessorials,
    });

    function groceryWarehouseInfoMessage() {
        if (
            groceryWarehouseName !== undefined &&
            location.locationType !== LocationType.GroceryWarehouse
        ) {
            return `${groceryWarehouseName}? Select Distribution Warehouse`;
        } else {
            return undefined;
        }
    }
    return (
        <HorizontalStack verticalAlign="top">
            <Stack align="left">
                <>
                    <Label>{postalCodeLabel}</Label>
                    <PostalCodeInput
                        name={`${displayName}Code`}
                        errorMessage={locationErrorMessages.address.postalCode}
                        warningMessage={
                            locationWarningMessages.address.postalCode
                        }
                        forceValidation={forceValidation}
                        postalCode={address?.postalCode || ''}
                        city={address?.city || ''}
                        stateOrProvinceCode={address?.stateOrProvinceCode || ''}
                        countryCode={address.countryCode}
                        onChange={onPostalCodeChange}
                        width={349}
                        displayName={displayName}
                        autoFocus={false}
                        enabled={!props.disablePostalCodeEntry}
                    />

                    <>
                        <SlideDown
                            show={showMap && address?.city !== undefined}
                        >
                            <MiniMap
                                longitude={longitude}
                                latitude={latitude}
                                city={address?.city}
                                stateOrProvinceCode={
                                    address?.stateOrProvinceCode
                                }
                                postalCode={address?.postalCode}
                            />
                        </SlideDown>
                    </>
                </>

                <Spacer height={25} />

                <>
                    <Label>
                        {displayName} Location Type
                        <LocationTypeQuestionBubble displayName={displayName} />
                    </Label>
                    <LocationDropdown
                        locationType={
                            location.locationType || LocationType.Warehouse
                        }
                        setLocationType={function (newLocationType) {
                            setLocationType(newLocationType);
                        }}
                        errorMessage={locationErrorMessages.locationType}
                        warningMessage={locationWarningMessages.locationType}
                        infoMessage={groceryWarehouseInfoMessage()}
                        forceValidation={forceValidation}
                    />
                </>
            </Stack>
            <Spacer width={60} />
            <Stack align="left">
                {schedulingAccessorials.length > 0 && (
                    <>
                        <Label>Scheduling</Label>
                        <Spacer height={15} />
                        <AccessorialsInput
                            accessorials={schedulingAccessorials}
                            selectedAccessorials={
                                selectedSchedulingAccessorials
                            }
                            selectedAccessorialsChanged={(
                                selectedAccessorials
                            ) => {
                                setSchedulingAccessorials(selectedAccessorials);
                            }}
                            locationContext={props.locationContext}
                            locationType={props.location.locationType}
                        />

                        <Spacer height={31} />
                    </>
                )}
                <>
                    <Label>Logistics</Label>
                    <Spacer height={15} />
                    <AccessorialsInput
                        accessorials={getAccessorials(
                            locationContext,
                            location.locationType,
                            'LOGISTICS_'
                        )}
                        selectedAccessorials={
                            accessorials.filter((a) =>
                                a.startsWith('LOGISTICS_')
                            ) || []
                        }
                        selectedAccessorialsChanged={(selectedAccessorials) => {
                            setLogisticsAccessorials(selectedAccessorials);
                        }}
                        locationContext={props.locationContext}
                        locationType={props.location.locationType}
                    />
                </>
            </Stack>
        </HorizontalStack>
    );
}

export default LocationDetails;
