/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BookingSuspendedReason } from './BookingSuspendedReason';
import {
    BookingSuspendedReasonFromJSON,
    BookingSuspendedReasonFromJSONTyped,
    BookingSuspendedReasonToJSON,
} from './BookingSuspendedReason';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { EmergencyContactDetails } from './EmergencyContactDetails';
import {
    EmergencyContactDetailsFromJSON,
    EmergencyContactDetailsFromJSONTyped,
    EmergencyContactDetailsToJSON,
} from './EmergencyContactDetails';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { PreQuoteLocation } from './PreQuoteLocation';
import {
    PreQuoteLocationFromJSON,
    PreQuoteLocationFromJSONTyped,
    PreQuoteLocationToJSON,
} from './PreQuoteLocation';
import type { Quote } from './Quote';
import {
    QuoteFromJSON,
    QuoteFromJSONTyped,
    QuoteToJSON,
} from './Quote';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface PreBookingShipment
 */
export interface PreBookingShipment {
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    shipmentId: string;
    /**
     * 
     * @type {Quote}
     * @memberof PreBookingShipment
     */
    selectedQuote?: Quote;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof PreBookingShipment
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    pickupDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    deliveryDeadline?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof PreBookingShipment
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {PreQuoteLocation}
     * @memberof PreBookingShipment
     */
    pickupLocation: PreQuoteLocation;
    /**
     * 
     * @type {PreQuoteLocation}
     * @memberof PreBookingShipment
     */
    deliveryLocation: PreQuoteLocation;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    pickupReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    deliveryReferenceNumber: string;
    /**
     * 
     * @type {Contact}
     * @memberof PreBookingShipment
     */
    pickupContact?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof PreBookingShipment
     */
    deliveryContact?: Contact;
    /**
     * 
     * @type {Broker}
     * @memberof PreBookingShipment
     */
    broker?: Broker;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    stripePaymentMethodId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    isReviewingPriorToBookingShipment: boolean;
    /**
     * 
     * @type {Quote}
     * @memberof PreBookingShipment
     */
    cheapestQuote?: Quote;
    /**
     * 
     * @type {ShipmentState}
     * @memberof PreBookingShipment
     */
    shipmentState: ShipmentState;
    /**
     * 
     * @type {EmergencyContactDetails}
     * @memberof PreBookingShipment
     */
    emergencyContactDetails?: EmergencyContactDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreBookingShipment
     */
    finalizeBookingStages: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    manualQuoting: boolean;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    manualQuotingNotes: string;
    /**
     * 
     * @type {number}
     * @memberof PreBookingShipment
     */
    paymentTermsDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    isBookingSuspended: boolean;
    /**
     * 
     * @type {BookingSuspendedReason}
     * @memberof PreBookingShipment
     */
    bookingSuspendedReason: BookingSuspendedReason;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    addInsuranceToShipment: boolean;
    /**
     * 
     * @type {number}
     * @memberof PreBookingShipment
     */
    insuranceAmount: number;
    /**
     * 
     * @type {Currency}
     * @memberof PreBookingShipment
     */
    insuranceCurrency: Currency;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    branchId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    quotedBy: string;
    /**
     * 
     * @type {number}
     * @memberof PreBookingShipment
     */
    linearFeet: number;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    exclusiveUseNeeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreBookingShipment
     */
    tarpRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    pickupBoothNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PreBookingShipment
     */
    deliveryBoothNumber: string;
}

/**
 * Check if a given object implements the PreBookingShipment interface.
 */
export function instanceOfPreBookingShipment(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('pickupReferenceNumber' in value)) return false;
    if (!('deliveryReferenceNumber' in value)) return false;
    if (!('isReviewingPriorToBookingShipment' in value)) return false;
    if (!('shipmentState' in value)) return false;
    if (!('finalizeBookingStages' in value)) return false;
    if (!('manualQuoting' in value)) return false;
    if (!('manualQuotingNotes' in value)) return false;
    if (!('paymentTermsDays' in value)) return false;
    if (!('isBookingSuspended' in value)) return false;
    if (!('bookingSuspendedReason' in value)) return false;
    if (!('addInsuranceToShipment' in value)) return false;
    if (!('insuranceAmount' in value)) return false;
    if (!('insuranceCurrency' in value)) return false;
    if (!('quotedBy' in value)) return false;
    if (!('linearFeet' in value)) return false;
    if (!('exclusiveUseNeeded' in value)) return false;
    if (!('tarpRequired' in value)) return false;
    if (!('pickupBoothNumber' in value)) return false;
    if (!('deliveryBoothNumber' in value)) return false;
    return true;
}

export function PreBookingShipmentFromJSON(json: any): PreBookingShipment {
    return PreBookingShipmentFromJSONTyped(json, false);
}

export function PreBookingShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreBookingShipment {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'selectedQuote': json['selectedQuote'] == null ? undefined : QuoteFromJSON(json['selectedQuote']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'pickupDate': json['pickupDate'],
        'pickupDeadline': json['pickupDeadline'] == null ? undefined : json['pickupDeadline'],
        'deliveryDeadline': json['deliveryDeadline'] == null ? undefined : json['deliveryDeadline'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'pickupLocation': PreQuoteLocationFromJSON(json['pickupLocation']),
        'deliveryLocation': PreQuoteLocationFromJSON(json['deliveryLocation']),
        'pickupReferenceNumber': json['pickupReferenceNumber'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'],
        'pickupContact': json['pickupContact'] == null ? undefined : ContactFromJSON(json['pickupContact']),
        'deliveryContact': json['deliveryContact'] == null ? undefined : ContactFromJSON(json['deliveryContact']),
        'broker': json['broker'] == null ? undefined : BrokerFromJSON(json['broker']),
        'stripePaymentMethodId': json['stripePaymentMethodId'] == null ? undefined : json['stripePaymentMethodId'],
        'isReviewingPriorToBookingShipment': json['isReviewingPriorToBookingShipment'],
        'cheapestQuote': json['cheapestQuote'] == null ? undefined : QuoteFromJSON(json['cheapestQuote']),
        'shipmentState': ShipmentStateFromJSON(json['shipmentState']),
        'emergencyContactDetails': json['emergencyContactDetails'] == null ? undefined : EmergencyContactDetailsFromJSON(json['emergencyContactDetails']),
        'finalizeBookingStages': json['finalizeBookingStages'],
        'manualQuoting': json['manualQuoting'],
        'manualQuotingNotes': json['manualQuotingNotes'],
        'paymentTermsDays': json['paymentTermsDays'],
        'isBookingSuspended': json['isBookingSuspended'],
        'bookingSuspendedReason': BookingSuspendedReasonFromJSON(json['bookingSuspendedReason']),
        'addInsuranceToShipment': json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'],
        'insuranceCurrency': CurrencyFromJSON(json['insuranceCurrency']),
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
        'quotedBy': json['quotedBy'],
        'linearFeet': json['linearFeet'],
        'exclusiveUseNeeded': json['exclusiveUseNeeded'],
        'tarpRequired': json['tarpRequired'],
        'pickupBoothNumber': json['pickupBoothNumber'],
        'deliveryBoothNumber': json['deliveryBoothNumber'],
    };
}

export function PreBookingShipmentToJSON(value?: PreBookingShipment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'selectedQuote': QuoteToJSON(value['selectedQuote']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'pickupDate': value['pickupDate'],
        'pickupDeadline': value['pickupDeadline'],
        'deliveryDeadline': value['deliveryDeadline'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'pickupLocation': PreQuoteLocationToJSON(value['pickupLocation']),
        'deliveryLocation': PreQuoteLocationToJSON(value['deliveryLocation']),
        'pickupReferenceNumber': value['pickupReferenceNumber'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'pickupContact': ContactToJSON(value['pickupContact']),
        'deliveryContact': ContactToJSON(value['deliveryContact']),
        'broker': BrokerToJSON(value['broker']),
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'isReviewingPriorToBookingShipment': value['isReviewingPriorToBookingShipment'],
        'cheapestQuote': QuoteToJSON(value['cheapestQuote']),
        'shipmentState': ShipmentStateToJSON(value['shipmentState']),
        'emergencyContactDetails': EmergencyContactDetailsToJSON(value['emergencyContactDetails']),
        'finalizeBookingStages': value['finalizeBookingStages'],
        'manualQuoting': value['manualQuoting'],
        'manualQuotingNotes': value['manualQuotingNotes'],
        'paymentTermsDays': value['paymentTermsDays'],
        'isBookingSuspended': value['isBookingSuspended'],
        'bookingSuspendedReason': BookingSuspendedReasonToJSON(value['bookingSuspendedReason']),
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyToJSON(value['insuranceCurrency']),
        'branchId': value['branchId'],
        'quotedBy': value['quotedBy'],
        'linearFeet': value['linearFeet'],
        'exclusiveUseNeeded': value['exclusiveUseNeeded'],
        'tarpRequired': value['tarpRequired'],
        'pickupBoothNumber': value['pickupBoothNumber'],
        'deliveryBoothNumber': value['deliveryBoothNumber'],
    };
}

