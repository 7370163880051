import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import NumberInput from 'Cargo/Controls/NumberInput';
import { TextArea } from 'Cargo/Controls/Textarea';
import { ReactionType, useReaction } from 'Cargo/Hooks/Reaction';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { Microcopy, ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { ErrorMessageType } from 'Cargo/Validation';
import { Dimension, Units } from 'Helpers/units';
import {
    LineItem,
    PreQuoteLocation,
    SharedDedicated,
} from 'generated-openapi-client';
import { EquipmentType } from 'generated-openapi-client/models/EquipmentType';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EquipmentTypeDropdown } from './EquipmentTypeDropdown';
import { SharedDedicatedDropdown } from './SharedDedicatedDropdown';
import { TarpingRequiredDropdown } from './TarpingRequiredDropdown';
import { TwoColumnShipmentView } from './TwoColumnShipmentView';

const Label = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: -8px;
`;

interface ConfirmManualQuoteRequestModalProps {
    onFinished: (
        params: ConfirmManualQuoteRequestModalCallbackData
    ) => Promise<void>;
    onCancel: () => void;
    linearFeet: number;
}

function errorMessageForRequired<T>(value: T | undefined): ErrorMessageType {
    if (value === undefined) {
        return 'Required';
    }

    return undefined;
}

function errorMessageForTarpRequired(
    equipmentType: EquipmentType | undefined,
    tarpRequired: undefined | boolean
) {
    if (equipmentType !== EquipmentType.FlatBed) {
        return undefined;
    }

    return errorMessageForRequired(tarpRequired);
}

function errorMessageForLinearFeet(
    sharedDedicated: SharedDedicated | undefined,
    linearFeet: undefined | number
): ErrorMessageType {
    if (sharedDedicated === SharedDedicated.Dedicated) {
        return undefined;
    }

    if (linearFeet === undefined) {
        return 'Required';
    }

    if (linearFeet < 2) {
        return 'Too Small';
    }

    if (linearFeet > 53) {
        return 'Too Large';
    }
    return undefined;
}

function ConfirmManualQuoteRequestModal(
    props: ConfirmManualQuoteRequestModalProps
) {
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [equipmentType, setEquipmentType] = useState<
        EquipmentType | undefined
    >();
    const [sharedDedicated, setSharedDedicated] = useState<
        SharedDedicated | undefined
    >();
    const [tarpRequired, setTarpRequired] = useState<boolean | undefined>();
    const [linearFeet, setLinearFeet] = useState<number | undefined>(
        props.linearFeet
    );
    const [forceValidation, setForceValidation] = useState(false);

    const equipmentTypeErrorMessage = errorMessageForRequired(equipmentType);
    const sharedDedicatedErrorMessage =
        errorMessageForRequired(sharedDedicated);
    const tarpRequiredErrorMessage = errorMessageForTarpRequired(
        equipmentType,
        tarpRequired
    );

    const linearFeetErrorMessage = errorMessageForLinearFeet(
        sharedDedicated,
        linearFeet
    );

    function isValid(): boolean {
        if (equipmentTypeErrorMessage !== undefined) {
            return false;
        }

        if (sharedDedicatedErrorMessage !== undefined) {
            return false;
        }

        if (tarpRequiredErrorMessage !== undefined) {
            return false;
        }

        if (linearFeetErrorMessage !== undefined) {
            return false;
        }

        return true;
    }

    async function onFinished() {
        setForceValidation(true);

        if (!isValid()) {
            return;
        }

        if (equipmentType === undefined || sharedDedicated === undefined) {
            // Shouldn't be here
            return;
        }

        setLoading(true);
        await props.onFinished({
            equipmentType,
            sharedDedicated,
            tarpRequired,
            linearFeet,
            notes,
        });
        setLoading(false);
    }
    return (
        <Stack align="center">
            <Stack align="left">
                <ModalTitle>Additional Details</ModalTitle>
                <Spacer height={8} />
                <Microcopy>
                    Requesting manual quotes will instruct our dedicated
                    logistics team to start working on quotes for your shipment.
                    They're keen, eager to help, and have a ton of experience to
                    make sure your shipments go smoothly.
                </Microcopy>
                <Spacer height={24} />
                <HorizontalStack verticalAlign="top">
                    <Stack align="left">
                        <Label>What equipment do you need?</Label>
                        <Spacer height={5} />
                        <EquipmentTypeDropdown
                            equipmentType={equipmentType}
                            onEquipmentTypeChange={setEquipmentType}
                            enabled={true}
                            errorMessage={equipmentTypeErrorMessage}
                            forceValidation={forceValidation}
                        />
                    </Stack>
                    <Spacer width={64} />
                    <Stack align="left">
                        {equipmentType === EquipmentType.FlatBed && (
                            <>
                                <Label>Do you require a tarp?</Label>
                                <Spacer height={5} />
                                <TarpingRequiredDropdown
                                    value={tarpRequired}
                                    onChange={setTarpRequired}
                                    enabled={true}
                                    errorMessage={tarpRequiredErrorMessage}
                                    forceValidation={forceValidation}
                                />
                            </>
                        )}
                    </Stack>
                </HorizontalStack>
                <Spacer height={32} />
                <HorizontalStack verticalAlign="top">
                    <Stack align="left">
                        <Label>Do you need shared, or dedicated use?</Label>
                        <Spacer height={5} />
                        <SharedDedicatedDropdown
                            value={sharedDedicated}
                            onChange={setSharedDedicated}
                            enabled={true}
                            errorMessage={sharedDedicatedErrorMessage}
                            forceValidation={forceValidation}
                        />
                    </Stack>
                    <Spacer width={64} />
                    <Stack align="left">
                        {(sharedDedicated === SharedDedicated.Shared ||
                            sharedDedicated === SharedDedicated.Either) && (
                            <>
                                <Label>Linear Feet</Label>
                                <HorizontalStack verticalAlign="middle">
                                    <NumberInput
                                        errorMessage={linearFeetErrorMessage}
                                        forceValidation={forceValidation}
                                        units={Units.Imperial}
                                        dimension={Dimension.Length}
                                        value={linearFeet}
                                        onChange={setLinearFeet}
                                        width={60}
                                    />
                                    <Spacer width={8} />
                                    <div style={{ marginTop: '-6px' }}>
                                        feet
                                    </div>
                                </HorizontalStack>
                            </>
                        )}
                    </Stack>
                </HorizontalStack>
                <Spacer height={32} />

                {/* <PlaceholderLabel>Number of Trucks</PlaceholderLabel>
                <Stepper value={numberTrucks} onChange={setNumberTrucks} /> */}
                <Label>Notes</Label>
                <Spacer height={16} />
                <TextArea
                    rows={4}
                    style={{ width: '800px' }}
                    value={notes}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setNotes(e.currentTarget.value);
                    }}
                    name="notes"
                    autoComplete="off"
                    placeholder="Enter any extra relevant information here that would help our team get the right quotes for you"
                />
                <Spacer height={48} />
                <HorizontalStack align="spread">
                    <Button onClick={props.onCancel} secondary>
                        Cancel
                    </Button>
                    <Spacer width={8} />
                    <Button loading={loading} onClick={onFinished}>
                        Get to work!
                    </Button>
                </HorizontalStack>
            </Stack>
        </Stack>
    );
}

interface ConfirmManualQuoteRequestModalCallbackData {
    notes: string;
    equipmentType: EquipmentType;
    sharedDedicated: SharedDedicated;
    tarpRequired: boolean | undefined;
    linearFeet: number | undefined;
}

function useConfirmManualQuoteRequestModal(linearFeet: number) {
    const showModal = useModal<ConfirmManualQuoteRequestModalCallbackData>();

    async function show(): Promise<ConfirmManualQuoteRequestModalCallbackData> {
        return new Promise<ConfirmManualQuoteRequestModalCallbackData>(
            (resolve) => {
                showModal(
                    (done) => {
                        return (
                            <ConfirmManualQuoteRequestModal
                                onCancel={() => done()}
                                onFinished={async (params) => {
                                    done(params);
                                }}
                                linearFeet={linearFeet}
                            />
                        );
                    },
                    async (data) => {
                        if (data !== undefined) {
                            resolve(data);
                        }
                    },
                    {
                        preventBackdropClick: true,
                    }
                );
            }
        );
    }
    return show;
}

export enum DedicatedLogisticsOfferButtonPosition {
    Top,
    Inline,
}

interface DedicatedLogisticsOfferProps {
    shipmentId: UUID;
    onModify: () => void;
    onRequestManualQuotes: (
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) => Promise<void>;
    lineItems: LineItem[];
    pickupDate: string;
    pickupDeadline: string | undefined;
    pickupLocation: PreQuoteLocation;
    deliveryLocation: PreQuoteLocation;
    deliveryDeadline: string | undefined;
    hideModifyButton?: boolean;
    buttonPosition: DedicatedLogisticsOfferButtonPosition;
    equipmentType: EquipmentType | undefined;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    linearFeet: number;
}

interface LeftSideProps {
    pickupDeadline: string | undefined;
    lineItems: LineItem[];
    pickupDate: string;
    pickupLocation: PreQuoteLocation;
    deliveryDeadline: string | undefined;
    deliveryLocation: PreQuoteLocation;
    equipmentType: EquipmentType | undefined;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    linearFeet: number;
}

function LeftSide(props: LeftSideProps) {
    return <TwoColumnShipmentView {...props} />;
}

interface RightSideProps {
    onModify: () => void;
    onConfirm: () => void;
    hideModifyButton: boolean | undefined;
    buttonPosition: DedicatedLogisticsOfferButtonPosition;
}

function RightSide(props: RightSideProps) {
    return (
        <Stack align="center" style={{ width: '40%' }}>
            <Stack align="left">
                <Microcopy>
                    For some larger or more complex shipments, the range of
                    instant quotes may be limited.
                </Microcopy>
                <Spacer height={24} />
                <Microcopy>
                    Our dedicated logistics team will help you get quotes for
                    these more difficult shipments.
                </Microcopy>
                <Spacer height={24} />
                <Microcopy>
                    Our team will work with our carrier partners to find the
                    best options for your shipment, and contact you when they're
                    ready - usually within a few hours.
                </Microcopy>
            </Stack>
            <Spacer height={48} />
            {props.buttonPosition ===
                DedicatedLogisticsOfferButtonPosition.Inline && (
                <>
                    <Button size="large" onClick={props.onConfirm}>
                        Request Manual Quotes 💪
                    </Button>
                    {!props.hideModifyButton && (
                        <>
                            <Spacer height={8} />
                            <LinkButton onClick={props.onModify}>
                                Modify Shipment Details
                            </LinkButton>
                        </>
                    )}
                </>
            )}
        </Stack>
    );
}

export function DedicatedLogisticsOffer(props: DedicatedLogisticsOfferProps) {
    const showModal = useConfirmManualQuoteRequestModal(props.linearFeet);
    const { showReaction } = useReaction();
    const navigate = useNavigate();

    async function onConfirm() {
        const result = await showModal();
        if (result !== undefined) {
            await props.onRequestManualQuotes(
                props.shipmentId,
                result.equipmentType,
                result.sharedDedicated,
                result.tarpRequired,
                result.linearFeet,
                result.notes
            );
            showReaction(ReactionType.Success);
            navigate('/quotes');
        }
    }

    return (
        <Stack align="center">
            {props.buttonPosition ===
                DedicatedLogisticsOfferButtonPosition.Top && (
                <>
                    <Spacer height={32} />
                    <Button size="xl" onClick={onConfirm}>
                        Click to Request More Quotes 💪
                    </Button>
                    {!props.hideModifyButton && (
                        <>
                            <Spacer height={8} />
                            <LinkButton onClick={props.onModify}>
                                Modify Shipment Details
                            </LinkButton>
                        </>
                    )}
                </>
            )}
            <HorizontalStack
                style={{ padding: '64px' }}
                align="spread"
                verticalAlign="top"
            >
                <LeftSide
                    pickupDeadline={props.pickupDeadline}
                    pickupDate={props.pickupDate}
                    lineItems={props.lineItems}
                    pickupLocation={props.pickupLocation}
                    deliveryLocation={props.deliveryLocation}
                    deliveryDeadline={props.deliveryDeadline}
                    equipmentType={props.equipmentType}
                    exclusiveUse={props.exclusiveUse}
                    tarpRequired={props.tarpRequired}
                    linearFeet={props.linearFeet}
                />
                <RightSide
                    onModify={props.onModify}
                    onConfirm={onConfirm}
                    hideModifyButton={props.hideModifyButton}
                    buttonPosition={props.buttonPosition}
                />
            </HorizontalStack>
        </Stack>
    );
}
