import Colors from 'Cargo/Colors';
import LinkButton from 'Cargo/Controls/LinkButton';
import ProgressBar from 'Cargo/Controls/ProgressBar';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useMultiOptionConfirmModal from 'Cargo/Modal/useMultiOptionConfirmModal';
import { H0, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { NoQuotes } from 'Features/BookShipment/Components/NoQuotes';
import QuotingSuccessAnimation from 'Features/Quotes/Components/QuotingSuccessAnimation';
import {
    SortQuotes,
    SortQuotesOptions,
} from 'Features/Quotes/Components/SortQuotes';
import ViewQuotes from 'Features/ViewShipments/Components/ViewQuotes';
import { useOnce } from 'Hooks/useOnce';
import { useShipmentService } from 'Services/ShipmentService';
import {
    EquipmentType,
    LineItem,
    PreBookingShipment,
    PreQuoteLocation,
    Quote,
    SavedLocation,
    SharedDedicated,
} from 'generated-openapi-client';
import { Currency } from 'generated-openapi-client/models/Currency';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCoupon } from '../Coupons/Hooks/useCoupon';
import {
    loadFromShipmentToModify,
    resetShipment,
} from './Slices/bookShipmentSlice';
import { useBookShipmentWarningMessages } from './Hooks/useBookShipmentWarningMessages';
import { BookShipmentWarningMessages } from './Components/BookShipmentWarningMessages';

interface BookShipmentQuotesScreenProps {
    shipment: PreBookingShipment;
    savedLocations: Array<SavedLocation>;
    defaultPickupLocationId: UUID | undefined;
    defaultDeliveryLocationId: UUID | undefined;
}

function sortQuotes(
    sortBy: SortQuotesOptions,
    quotes: readonly Quote[] | undefined
): Quote[] | undefined {
    if (quotes == undefined) {
        return undefined;
    }

    return quotes.slice().sort(function (a, b) {
        if (sortBy === SortQuotesOptions.SortByLowestPrice) {
            return a.price - b.price;
        } else {
            // This is a total guess right now - but
            // assume that the mean of the distribution of transit times
            // is 30% into the window
            const averageTransitDaysA =
                ((a.latestTransitBusinessDays ?? 1000) +
                    2 * (a.transitBusinessDays ?? 1000)) /
                3;
            const averageTransitDaysB =
                ((b.latestTransitBusinessDays ?? 1000) +
                    2 * (b.transitBusinessDays ?? 1000)) /
                3;

            return averageTransitDaysA - averageTransitDaysB;
        }
    });
}

// eg. YRC has two different Standard services and we only want to show one
// Standard and DimensionalStandard
//
// So we sift the quotes into a map based on a key of the carrierIdentifier + the displayname of the service
// Then from each bucket we only take the cheapest quote
function filterQuotes(
    quotes: readonly Quote[] | undefined
): Quote[] | undefined {
    console.log(`filterQuotes`, { quotes });
    if (quotes == undefined) {
        return undefined;
    }

    const map = new Map<string, Quote[]>();

    quotes.forEach(function (quote) {
        const serviceLevel = quote.serviceDisplayName;
        const key = quote.carrierIdentifier + '|' + serviceLevel;
        map.set(key, map.get(key) || []);
        map.get(key)?.push(quote);
    });

    const filteredQuotes: Quote[] = [];

    map.forEach(function (quotes: Quote[]) {
        quotes.sort(function (a, b) {
            return a.price - b.price;
        });

        filteredQuotes.push(quotes[0]);
    });

    return filteredQuotes;
}

interface ShowQuotesProps {
    waitingForQuotes: boolean;
    quotes?: Readonly<Array<Quote>>;
    onModify: () => void;
    onBook: (quoteId: UUID) => void;
    pickupDate: moment.Moment;
    pickupLocation: PreQuoteLocation;
    deliveryLocation: PreQuoteLocation;
    deliveryDeadline: string | undefined;
    lineItems: Array<LineItem>;
    linearFeet: number;
    shipment: PreBookingShipment;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: Currency;
    equipmentType: EquipmentType | undefined;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    onRequestManualQuotes: (
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) => Promise<void>;
}

function ShowQuotes(props: ShowQuotesProps) {
    const {
        waitingForQuotes,
        quotes,
        onBook,
        pickupDate,
        pickupLocation,
        deliveryDeadline,
        deliveryLocation,
        lineItems,
        linearFeet,
        onRequestManualQuotes,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
        equipmentType,
        exclusiveUse,
        tarpRequired,
    } = props;

    const [wasWaitingForQuotes] = useState(waitingForQuotes);

    const [sortBy, setSortBy] = useState<SortQuotesOptions>(
        SortQuotesOptions.SortByLowestPrice
    );

    const sortedQuotes = sortQuotes(sortBy, filterQuotes(quotes));

    return (
        <>
            {waitingForQuotes && (
                <>
                    <HorizontalStack width="500px">
                        <ProgressBar />
                    </HorizontalStack>
                    <Spacer height={32} />
                </>
            )}
            {wasWaitingForQuotes && !waitingForQuotes && (
                <QuotingSuccessAnimation />
            )}
            {sortedQuotes && (
                <>
                    {sortedQuotes.length > 1 && (
                        <HorizontalStack
                            width="100%"
                            align="right"
                            style={{ paddingRight: '16px' }}
                        >
                            <SortQuotes sortBy={sortBy} setSortBy={setSortBy} />
                        </HorizontalStack>
                    )}
                    <ViewQuotes
                        pickupDeadline={props.shipment.pickupDeadline}
                        shipmentId={props.shipment.shipmentId}
                        pickupDate={moment(pickupDate)}
                        pickupLocation={pickupLocation}
                        lineItems={lineItems}
                        linearFeet={linearFeet}
                        deliveryLocation={deliveryLocation}
                        onBook={onBook}
                        quotes={sortedQuotes}
                        sortBy={sortBy}
                        // TODO: Do we need this?
                        notes={undefined}
                        onRequestManualQuotes={onRequestManualQuotes}
                        addInsuranceToShipment={addInsuranceToShipment}
                        insuranceAmount={insuranceAmount}
                        insuranceCurrency={insuranceCurrency}
                        equipmentType={equipmentType}
                        exclusiveUse={exclusiveUse}
                        tarpRequired={tarpRequired}
                        deliveryDeadline={deliveryDeadline}
                    />
                </>
            )}
        </>
    );
}

interface CopyProps {
    onModify: () => void;
    onRestart: (() => void) | undefined;
}

function Copy(props: CopyProps) {
    const { onRestart, onModify } = props;

    if (onRestart !== undefined) {
        return (
            <>
                <Microcopy>
                    Please review the quotes and select the one you would like
                    to book.
                </Microcopy>
                <Microcopy>
                    If you&apos;re not quite ready to book now, you can find
                    these quotes saved on in the 'Recent Quotes' tab.
                </Microcopy>
                <Microcopy>
                    Keep in mind that if the shipment differs in any way from
                    the details entered, these quotes may not be valid.
                </Microcopy>
                <Spacer height={8} />
                <HorizontalStack>
                    <LinkButton style={{ fontSize: '16px' }} onClick={onModify}>
                        Modify Details
                    </LinkButton>
                    <Spacer width={16} />
                    <LinkButton
                        style={{ fontSize: '16px' }}
                        onClick={onRestart}
                    >
                        Get Quotes for a Different Shipment
                    </LinkButton>
                </HorizontalStack>
            </>
        );
    } else {
        return (
            <Microcopy>
                Please review the quotes and select the one you would like to
                book. After you select a quote, there are a few quick steps to
                finalize the shipment.{' '}
                <LinkButton style={{ fontSize: '16px' }} onClick={onModify}>
                    Click here
                </LinkButton>{' '}
                to modify your details of your shipment.
            </Microcopy>
        );
    }
}

function BookShipmentQuotesScreen(props: BookShipmentQuotesScreenProps) {
    const {
        shipment,
        savedLocations,
        defaultPickupLocationId,
        defaultDeliveryLocationId,
    } = props;
    const shipmentsService = useShipmentService();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const {
        shipmentId,
        pickupDate,
        pickupLocation,
        deliveryLocation,
        deliveryDeadline,
        lineItems,
        linearFeet,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
        equipmentType,
        exclusiveUseNeeded,
        tarpRequired,
    } = shipment;

    const { warnings } = useBookShipmentWarningMessages({
        pickupDate,
        lineItems,
        pickupLocation,
        deliveryLocation,
    });

    const location = useLocation();

    useEffect(() => {
        function onPop() {
            if (location.pathname === '/book/details') {
                const shipmentIdToDelete = shipmentId;
                dispatch(
                    loadFromShipmentToModify({
                        shipment,
                        savedLocations,
                    })
                );
                shipmentsService.deleteQuotedShipment(
                    shipmentIdToDelete,
                    'Modified for new quotes'
                );
                navigate('/book/details');
            }
        }

        window.addEventListener('popstate', onPop);

        return () => window.removeEventListener('popstate', onPop);
    }, []);

    // const { onShipmentBooked } = useGoogleAdsConversions();

    // useEffect(function () {
    //     onShipmentBooked();
    // }, []);

    const confirmModify = useMultiOptionConfirmModal(
        'Would you like to keep the current quotes before modifying?',
        `You will be able to find them in the 'Recent Quotes' tab.`,
        {
            buttons: ['👎  Discard', '👍  Keep'],
        }
    );

    const confirmRestart = useMultiOptionConfirmModal(
        'Would you like to keep the current quotes before restarting?',
        `You will be able to find them in the 'Recent Quotes' tab.`,
        {
            buttons: ['👎  Discard', '👍  Keep'],
        }
    );

    const [quotes, setQuotes] = useState<Array<Quote> | undefined>();
    const [loadingQuotes, setLoadingQuotes] = useState(false);
    const { discountQuote } = useCoupon();

    async function loadQuotes() {
        setLoadingQuotes(true);
        const quotesResponse = await shipmentsService.pollForQuotes(shipmentId);
        setQuotes(
            quotesResponse.quotes

                // Apply the coupon to reduce the rates
                // This is just presentation logic. The server will correctly apply the
                // coupon
                .map(function (q) {
                    return discountQuote(q);
                })
        );

        console.log(`loadQuotes`, { quotesResponse });

        if (!quotesResponse.complete) {
            console.log(`loadQuotes - not complete, so continuing to poll`);
            setTimeout(loadQuotes, 1000);
        } else {
            console.log(`loadQuotes - done`);
            setLoadingQuotes(false);
        }
    }

    async function onSelectQuote(quoteId: UUID) {
        console.log('onSelectQuote');
        await shipmentsService.selectQuote(shipmentId, quoteId);
        console.log('onSelectQuote - pushing to pickup-address');
        navigate(`/book/pickup-address?shipmentId=${shipmentId}`);
    }

    useOnce(async () => {
        loadQuotes();
    });

    // TODO : Move this up to the router level
    async function onModify() {
        // If there are no quotes, then assume that the user just entered 'Discard'
        // Discard is the button at index 0
        const modifyResponse = quotes?.length === 0 ? 0 : await confirmModify(); // undefined=cancel, 0=Discard, 1=Keep

        const shipmentIdToDelete = shipmentId;

        // undefined means 'Cancel'
        if (modifyResponse !== undefined) {
            dispatch(loadFromShipmentToModify({ shipment, savedLocations }));
            if (modifyResponse === 0 /* ie. discard */) {
                await shipmentsService.deleteQuotedShipment(
                    shipmentIdToDelete,
                    'Modified for new quotes'
                );
            }

            navigate('/book/details');
        }
    }

    async function onRequestManualQuotes(
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) {
        await shipmentsService.requestManualQuotes(
            shipmentId,
            equipmentType,
            sharedDedicated,
            tarpRequired,
            linearFeet,
            notes
        );
    }

    async function onRestart() {
        const modifyResponse = await confirmRestart(); // undefined=cancel, 0=Discard, 1=Keep

        const shipmentIdToDelete = shipmentId;

        // undefined means 'Cancel'
        if (modifyResponse !== undefined) {
            navigate('/book/details');
            dispatch(
                resetShipment({
                    savedLocations,
                    defaultPickupLocationId,
                    defaultDeliveryLocationId,
                })
            );
            if (modifyResponse === 0 /* ie. discard */) {
                await shipmentsService.deleteQuotedShipment(
                    shipmentIdToDelete,
                    'Restarted, discarded quotes'
                );
            }
        }
    }

    const headline = loadingQuotes
        ? 'Fetching Quotes'
        : 'Quotes for your shipment';

    if (!loadingQuotes && quotes?.length == 0) {
        return (
            <NoQuotes
                onModify={onModify}
                onRequestManualQuotes={onRequestManualQuotes}
                shipment={shipment}
            />
        );
    }


    return (
        <Stack width="100%" align="center">
            <H0>{headline}</H0>
            {!loadingQuotes && (
                <Copy onModify={onModify} onRestart={onRestart} />
            )}
            {loadingQuotes && (
                <Microcopy>
                    Connecting with carriers to find the best options for your
                    shipment{' '}
                </Microcopy>
            )}
            <Spacer height={8} />
            {warnings.length > 0 && (
                <>
                    <Spacer height={24} />
                    <div style={{ fontSize: '16px', color: Colors.LightText }}>
                        <BookShipmentWarningMessages warnings={warnings} />
                    </div>
                </>
            )}
            <Spacer height={32} />
            <ShowQuotes
                waitingForQuotes={loadingQuotes}
                quotes={quotes}
                onModify={onModify}
                onBook={onSelectQuote}
                pickupDate={moment(pickupDate)}
                pickupLocation={pickupLocation}
                deliveryLocation={deliveryLocation}
                deliveryDeadline={deliveryDeadline}
                lineItems={lineItems}
                linearFeet={linearFeet}
                shipment={shipment}
                onRequestManualQuotes={onRequestManualQuotes}
                addInsuranceToShipment={addInsuranceToShipment}
                insuranceAmount={insuranceAmount}
                insuranceCurrency={insuranceCurrency}
                equipmentType={equipmentType}
                exclusiveUse={exclusiveUseNeeded}
                tarpRequired={tarpRequired}
            />
        </Stack>
    );
}

export default BookShipmentQuotesScreen;
