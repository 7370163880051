import { Accessorials } from 'apis';
import AppointmentRequiredQuestionBubble from 'Features/Locations/Components/QuestionBubbles/AppointmentRequiredQuestionBubble';
import InsideQuestionBubble from 'Features/Locations/Components/QuestionBubbles/InsideQuestionBubble';
import LiftGateRequiredQuestionBubble from 'Features/Locations/Components/QuestionBubbles/LiftGateRequiredQuestionBubble';
import { LocationContext, LocationType } from 'generated-openapi-client';
import { ReactElement } from 'react';

interface UnscopedAccessorialType {
    name: string;
    counterPickup: string;
    counterDelivery: string;
    pickupName: string;
    deliveryName: string;
    description: string;
    pickupDescription: string;
    deliveryDescription: string;
    type: string;
    comingSoon?: boolean;
    questionBubble: (locationContext: LocationContext) => ReactElement;
}

export interface AccessorialType {
    name: string;
    description: string;
    type: string;
    comingSoon?: boolean;
    questionBubble: (locationContext: LocationContext) => ReactElement;
}

export function generateAccessorialTypes(
    locationType: LocationType
): Array<UnscopedAccessorialType> {
    return [
        // LOGISTICS_LIFT_GATE_REQUIRED
        {
            name: 'Lift gate required on truck',
            counterPickup: 'No Lift Gate Required',
            counterDelivery: 'No Lift Gate Required',
            pickupName: 'Lift gate required on truck',
            deliveryName: 'Lift gate required on truck',
            description: 'The location does not have a loading dock',
            pickupDescription:
                'The pickup location does not have a loading dock',
            deliveryDescription:
                'The delivery location does not have a loading dock',
            type: 'LOGISTICS_LIFT_GATE_REQUIRED',
            questionBubble: (locationContext: LocationContext) => (
                <LiftGateRequiredQuestionBubble
                    locationContext={locationContext}
                />
            ),
        },

        // LOGISTICS_INSIDE
        {
            name: 'Inside location',
            counterPickup: 'No Inside Pickup',
            counterDelivery: 'No Inside Delivery',
            pickupName: 'Pickup inside the location',
            deliveryName: 'Delivery inside the location',
            description: 'When the freight is located inside the building',
            pickupDescription:
                'The freight needs to be brought from inside the building',
            deliveryDescription:
                'The freight needs to be brought inside the building',
            type: 'LOGISTICS_INSIDE',
            questionBubble: (locationContext: LocationContext) => (
                <InsideQuestionBubble locationContext={locationContext} />
            ),
        },

        // SCHEDULING_APPOINTMENT_REQUIRED
        {
            name: 'Delivery appointment required',
            counterPickup: 'No Pickup Appointment',
            counterDelivery: 'No Delivery Appointment',
            pickupName: 'n/a',
            deliveryName: 'Delivery appointment required',
            pickupDescription: 'n/a',
            deliveryDescription:
                locationType === LocationType.GroceryWarehouse
                    ? 'A delivery appointment is required for distribution warehouses'
                    : 'The location requires the carrier to make a delivery appointment',
            description:
                'The location requires the carrier to make a delivery appointment',
            type: 'SCHEDULING_APPOINTMENT_REQUIRED',
            questionBubble: (locationContext: LocationContext) => (
                <AppointmentRequiredQuestionBubble
                    locationContext={locationContext}
                />
            ),
        },

        // Jan 2021: Only VanKam supports After hours through an API, so removing it for now
        // SCHEDULING_AFTER_HOURS
        // {
        //     name: 'After hours',
        //     pickupName: 'After hours pickup',
        //     deliveryName: 'After hours delivery',
        //     pickupDescription:
        //         'Pickup is required outside of normal business hours (9am-5pm)',
        //     deliveryDescription:
        //         'Delivery is required outside of normal business hours (9am-5pm)',
        //     description:
        //         'Access required outside of normal business hours (9am-5pm)',

        //     type: 'SCHEDULING_AFTER_HOURS',
        //     comingSoon: true,
        //     questionBubble: (locationContext?: LocationContext) => (
        //         <AfterHoursQuestionBubble locationContext={locationContext} />
        //     ),
        // },
    ];
}

function getName(
    a: UnscopedAccessorialType,
    scope: LocationContext | undefined
) {
    if (scope === LocationContext.Delivery) {
        return a.deliveryName;
    }

    if (scope === LocationContext.Pickup) {
        return a.pickupName;
    }

    if (scope === undefined || scope === LocationContext.Any) {
        return a.name;
    }

    throw new Error('Unhandled case');
}

function getDescription(
    a: UnscopedAccessorialType,
    scope: LocationContext | undefined
) {
    if (scope === LocationContext.Delivery) {
        return a.deliveryDescription;
    }

    if (scope === LocationContext.Pickup) {
        return a.pickupDescription;
    }

    if (scope === undefined || scope === LocationContext.Any) {
        return a.description;
    }

    throw new Error('Unhandled case');
}

function scopeAccessorials(
    scope: LocationContext | undefined,
    locationType: LocationType
): Array<AccessorialType> {
    const unscoped = generateAccessorialTypes(locationType).filter((o) => {
        if (scope === LocationContext.Pickup) {
            if (o.type === 'SCHEDULING_APPOINTMENT_REQUIRED') {
                return false;
            }
        }

        return true;
    });

    return unscoped.map((a) => ({
        name: getName(a, scope),
        description: getDescription(a, scope),
        type: a.type,
        comingSoon: a.comingSoon,
        questionBubble: a.questionBubble,
    }));
}

function accessorialFromType(
    scope: LocationContext | undefined,
    locationType: LocationType,
    type: string
) {
    const a = scopeAccessorials(scope, locationType).find(
        (accessorial) => accessorial.type === type
    );

    if (a === undefined) {
        return undefined;
    }

    return a;
}

export function getAccessorials(
    scope: LocationContext | undefined,
    locationType: LocationType,
    typePrefix?: string
) {
    const afterScope = scopeAccessorials(scope, locationType);

    if (typePrefix) {
        return afterScope.filter((item) => item.type?.startsWith(typePrefix));
    } else {
        return afterScope;
    }
}

export function describeAccessorials(
    accessorials: Accessorials,
    scope: LocationContext | undefined,
    locationType: LocationType
) {
    if (accessorials.length === 0) {
        return 'None';
    }

    return accessorials
        .map(
            (accessorialType) =>
                accessorialFromType(scope, locationType, accessorialType)
                    ?.description
        )
        .filter((o) => o !== undefined)
        .join(', ');
}

export function nameAccessorials(
    accessorials: Accessorials,
    scope: LocationContext | undefined,
    locationType: LocationType
) {
    const a = accessorials
        .map(
            (accessorialType) =>
                accessorialFromType(scope, locationType, accessorialType)?.name
        )
        .filter((o) => o !== undefined);

    if (a.length === 0) {
        return ['None Selected'];
    }

    return a;
}

export function nameAccessorialsExplicitly(
    accessorials: Accessorials,
    scope: LocationContext,
    locationType: LocationType
) {
    return generateAccessorialTypes(locationType)
        .filter(function (a) {
            if (
                scope == LocationContext.Pickup &&
                a.type === 'SCHEDULING_APPOINTMENT_REQUIRED'
            ) {
                return false;
            } else {
                return true;
            }
        })
        .map(function (a) {
            if (accessorials.includes(a.type)) {
                return a.name;
            } else {
                return scope === LocationContext.Pickup
                    ? a.counterPickup
                    : a.counterDelivery;
            }
        });
}
