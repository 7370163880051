import Fade from 'Cargo/Animations/Fade';
import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import RevealBox from 'Cargo/Layout/RevealBox';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { UUID } from 'Cargo/Types/types';
import {
    EquipmentType,
    LineItem,
    PreQuoteLocation,
    SharedDedicated,
} from 'generated-openapi-client';
import {
    DedicatedLogisticsOffer,
    DedicatedLogisticsOfferButtonPosition,
} from './DedicatedLogisticsOffer';

interface LogisticsQuoteRowProps {
    shipmentId: UUID;
    pickupDate: string;
    pickupDeadline: string | undefined;
    pickupLocation: PreQuoteLocation;
    deliveryLocation: PreQuoteLocation;
    deliveryDeadline: string | undefined;
    lineItems: LineItem[];
    equipmentType: EquipmentType | undefined;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    linearFeet: number;
    onRequestManualQuotes: (
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) => Promise<void>;
}

export function LogisticsQuoteRow(props: LogisticsQuoteRowProps) {
    return (
        <RevealBox
            header={() => {
                return (
                    <HorizontalStack>
                        <div style={{ fontSize: '26px' }}>🦸‍♀️</div>
                        <Spacer width={16} />
                        <Stack align="left">
                            <div style={{ fontSize: '18px', fontWeight: 500 }}>
                                Looking for more options?
                            </div>
                            <div
                                style={{
                                    fontSize: '14px',
                                    color: Colors.LightText,
                                    marginTop: '-2px',
                                }}
                            >
                                FreightSimple's Dedicated Logistics Team can
                                help find other options for this shipment
                            </div>
                        </Stack>
                    </HorizontalStack>
                );
            }}
            body={(open) => {
                return (
                    <Fade show={open}>
                        <DedicatedLogisticsOffer
                            pickupDeadline={props.pickupDeadline}
                            hideModifyButton
                            onModify={function () {}}
                            onRequestManualQuotes={props.onRequestManualQuotes}
                            shipmentId={props.shipmentId}
                            lineItems={props.lineItems}
                            pickupDate={props.pickupDate}
                            pickupLocation={props.pickupLocation}
                            deliveryLocation={props.deliveryLocation}
                            deliveryDeadline={props.deliveryDeadline}
                            buttonPosition={
                                DedicatedLogisticsOfferButtonPosition.Inline
                            }
                            equipmentType={props.equipmentType}
                            exclusiveUse={props.exclusiveUse}
                            tarpRequired={props.tarpRequired}
                            linearFeet={props.linearFeet}
                        />
                    </Fade>
                );
            }}
        />
    );
}
