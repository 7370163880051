import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { Microcopy } from 'Cargo/Text/Text';
import { PostalCodeChangeEvent } from 'Features/Locations/Components/PostalCodeInput';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { errorMessagesForLocation } from 'Features/Locations/Validators/errorMessagesForLocation';
import { Accessorials } from 'apis';
import { LocationContext, LocationType } from 'generated-openapi-client';
import { useState } from 'react';
import { warningMessagesForDeliveryLocation } from '../Validators/errorMessagesForDeliveryLocation';
import LocationDetails from './LocationDetails';

interface AddLocationModalDetailsPage {
    setLocation: (_: AddLocationLocationType) => void;
    location: AddLocationLocationType;
    onSwitchToAddressPage: () => void;
    onCancel: () => void;
    microcopy: string;
    context: LocationContext;
}

function AddLocationModalDetailsPage(props: AddLocationModalDetailsPage) {
    const { setLocation, location, onSwitchToAddressPage, onCancel } = props;

    // TODO: turn false into something meaningful
    const locationErrorMessages = errorMessagesForLocation(location, false);
    // We're using the delivery location here because delivery includes
    // appointments (whereas pickup currently does not)
    const locationWarningMessages = warningMessagesForDeliveryLocation(
        undefined,
        location
    );
    function isValid() {
        if (locationErrorMessages.address.postalCode !== undefined) {
            return false;
        } else {
            return true;
        }
    }

    const address = location.address;

    const [forceValidation, setForceValidation] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [groceryWarehouseName, setGroceryWarehouseName] = useState<
        string | undefined
    >();

    const accessorials = location.accessorials;

    function onPostalCodeChange(
        e: PostalCodeChangeEvent,
        _groceryWarehouseName: string | undefined
    ) {
        if (e.latitudeLongitude !== undefined) {
            setShowMap(true);
        } else {
            setShowMap(false);
        }

        const newLocation = {
            ...location,
            ...{ address: e.address, latitudeLongitude: e.latitudeLongitude },
        };

        setLocation(newLocation);
        setGroceryWarehouseName(_groceryWarehouseName);
    }

    function setLocationType(locationType: LocationType) {
        const newLocation = {
            ...location,
            ...{ locationType },
        };

        setLocation(newLocation);
    }

    function removeItemsStartingWith(values: string[], prefix: string) {
        return values.filter((v) => !v.startsWith(prefix));
    }

    function setSchedulingAccessorials(schedulingAccessorials: Accessorials) {
        const updatedAccessorials = removeItemsStartingWith(
            accessorials,
            'SCHEDULING_'
        );
        updatedAccessorials.push(...schedulingAccessorials);

        setLocation({
            ...location,
            ...{ accessorials: updatedAccessorials },
        });
    }

    function setLogisticsAccessorials(logisticsAccessorials: Accessorials) {
        const updatedAccessorials = removeItemsStartingWith(
            accessorials,
            'LOGISTICS_'
        );
        updatedAccessorials.push(...logisticsAccessorials);
        setLocation({
            ...location,
            ...{ accessorials: updatedAccessorials },
        });
    }

    function onNext() {
        if (isValid()) {
            onSwitchToAddressPage();
        } else {
            setForceValidation(true);
        }
    }

    return (
        <>
            <Microcopy>{props.microcopy}</Microcopy>
            <LocationDetails
                locationErrorMessages={locationErrorMessages}
                locationWarningMessages={locationWarningMessages}
                forceValidation={forceValidation}
                address={address}
                location={location}
                onPostalCodeChange={onPostalCodeChange}
                showMap={showMap}
                locationContext={props.context}
                latitudeLongitude={location.latitudeLongitude}
                setLocationType={setLocationType}
                accessorials={accessorials}
                setSchedulingAccessorials={setSchedulingAccessorials}
                setLogisticsAccessorials={setLogisticsAccessorials}
                disablePostalCodeEntry={false}
                groceryWarehouseName={groceryWarehouseName}
            />
            <Spacer height={48} />
            <HorizontalStack width="100%" align="spread">
                <Button onClick={onCancel} secondary>
                    Cancel
                </Button>
                <Button onClick={onNext}>Next</Button>
            </HorizontalStack>
        </>
    );
}

export default AddLocationModalDetailsPage;
