import DockHoursInput from 'Cargo/Controls/DockHoursInput';
import Input from 'Cargo/Controls/Input';
import Switch from 'Cargo/Controls/Switch';
import Box from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useAlertModal from 'Cargo/Modal/useAlertModal';
import { Label, Legalese } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import ContactQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/ContactQuestionBubble';
import NotesQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/NotesQuestionBubble';
import {
    BoothNumberQuestionBubble,
    ReferenceNumberQuestionBubble,
} from 'Features/BookShipment/Components/Addresses/QuestionBubbles/ReferenceNumberQuestionBubble';
import BookShipmentNavigationFooter2 from 'Features/BookShipment/Components/BookShipmentNavigationFooter2';
import SavedContactsDropdown from 'Features/BookShipment/Components/LocationDetails/SavedContactsDropdown';
import BookShipmentScreenLayout from 'Features/BookShipment/Layout/BookShipmentScreenLayout';
import ContactInput from 'Features/Contacts/Components/ContactInput';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import {
    EmailRequired,
    errorMessagesForContact,
    warningMessagesForContact,
} from 'Features/Contacts/Validators/errorMessagesForContact';
import AddressInput from 'Features/Locations/Components/AddressInput';
import { FavouriteStar } from 'Features/Locations/Components/LocationBox';
import DockHoursQuestionBubble from 'Features/Locations/Components/QuestionBubbles/DockHoursQuestionBubble';
import { findSavedLocationForLocation } from 'Features/Locations/Helpers/findSavedLocationForLocation';
import { useAddressOptions } from 'Features/Locations/Hooks/useAddressOptions';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { errorMessageForBoothNumber } from 'Features/Locations/Validators/errorMessageForBoothNumber';
import {
    errorMessageForDeadline,
    errorMessageForHasDeadline,
} from 'Features/Locations/Validators/errorMessageForDeadline';
import { errorMessageForNotes } from 'Features/Locations/Validators/errorMessageForNotes';
import { errorMessagesForLocation } from 'Features/Locations/Validators/errorMessagesForLocation';
import { useShipmentService } from 'Services/ShipmentService';
import { useGetStartedApi } from 'apis';
import {
    Address,
    Contact,
    Location,
    LocationContext,
    LocationType,
    PostShipmentsSetAddressAndContactRequest,
    PreBookingShipment,
    SavedLocation,
} from 'generated-openapi-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import styled from 'styled-components/macro';
import {
    loadFromShipmentToModify,
    setCurrentScreenIsDirty,
    setDeliveryDeadline,
    setDeliveryLocation,
    setHasDeliveryDeadline,
    setHasPickupDeadline,
    setPickupDeadline,
    setPickupLocation,
} from '../Slices/bookShipmentSlice';
import useResidentialConfirmModal, {
    ConfirmResidentialResult,
} from './Addresses/ConfirmResidentialModal';
import AddressQuestionBubble from './Addresses/QuestionBubbles/AddressQuestionBubble';
import BookShipmentSmallSection from './BookShipmentSmallSection';
import { DeadlineSection } from './DeadlineSection';
import LoadingShipment from './LoadingShipment';
import { warningMessageForPickupHours } from 'Features/Locations/Validators/errorMessageForHours';
import { ReferenceNumberInput } from 'Features/ReferenceNumbers/Components/ReferenceNumberInput';
import { useValidateReferenceNumber } from 'Features/ReferenceNumbers/Hooks/useValidateReferenceNumber';

interface AddressScreenComponentProps {
    identifier: string;

    savedLocations: Array<SavedLocation>;

    shipment: PreBookingShipment;

    context: LocationContext;

    // TODO: Probably just pass in a flow
    nextIdentifier: string;
    onPrevious: () => Promise<void>;

    loading: boolean;

    onComplete: () => void;
    onModify: () => void;

    markLocationAsFavourite: boolean;
    onMarkLocationAsFavourite: (newValue: boolean) => void;

    isSameDayPickup: boolean;
}

const Optional = styled.span`
    font-size: 14px;
    color: var(--freightsimple-color-light-text);
    font-weight: var(--nhu-font-weight-regular);
    margin-left: 8px;
`;

const AddressScreenComponent: React.FC<AddressScreenComponentProps> = (
    props: AddressScreenComponentProps
) => {
    const { loading, context, shipment, savedLocations } = props;

    const location =
        context === LocationContext.Pickup
            ? shipment.pickupLocation
            : shipment.deliveryLocation;

    const matchingSavedLocation = findSavedLocationForLocation(
        savedLocations,
        location
    );

    const dispatch = useDispatch();

    const { shipmentId, selectedQuote } = shipment;

    const navigate = useNavigate();

    const displayName =
        context === LocationContext.Pickup ? 'Pickup' : 'Delivery';

    const isPickup = context === LocationContext.Pickup;

    const pluralDisplayName =
        context === LocationContext.Pickup ? 'pickups' : 'deliveries';

    const [contact, setContact] = useState(
        (context === LocationContext.Pickup
            ? shipment.pickupContact
            : shipment.deliveryContact) || {}
    );

    const comparisonContacts: Map<string, AddContactContactType> = new Map();
    if (context === LocationContext.Delivery) {
        comparisonContacts.set('pickup', shipment.pickupContact || {});
    }

    const originalReferenceNumber =
        (context === LocationContext.Pickup
            ? shipment.pickupReferenceNumber
            : shipment.deliveryReferenceNumber) || '';

    const [referenceNumber, setReferenceNumber] = useState(
        originalReferenceNumber
    );
    const [boothNumber, setBoothNumber] = useState(
        (context === LocationContext.Pickup
            ? shipment.pickupBoothNumber
            : shipment.deliveryBoothNumber) || ''
    );
    const [forceValidation, setForceValidation] = useState(false);
    const [savedContactId, setSavedContactId] = useState<UUID>();

    // TODO: Move this out of getting started
    const getStartedApi = useGetStartedApi();
    const shipmentsService = useShipmentService();

    function findSavedContactToApply() {
        if (matchingSavedLocation) {
            if (matchingSavedLocation.contacts.length === 1) {
                return matchingSavedLocation.contacts[0];
            } else if (matchingSavedLocation.contacts.length > 1) {
                const defaultContact = matchingSavedLocation.contacts.find(
                    (c) =>
                        c.savedContactId ===
                        matchingSavedLocation.defaultSavedContactId
                );

                if (defaultContact) {
                    return defaultContact;
                } else {
                    return matchingSavedLocation.contacts[0];
                }
            }
        }
    }

    function updateContact(contactUpdates: Partial<Contact>) {
        console.log(`updateContact`, { contactUpdates });
        setContact({
            ...contact,
            ...contactUpdates,
        });
    }

    useEffect(function () {
        const c = findSavedContactToApply();

        if (c) {
            setSavedContactId(c.savedContactId);
            updateContact(c.contact);
        }
    }, []);

    function updateLocation(updatedLocation: AddLocationLocationType) {
        dispatch(setCurrentScreenIsDirty());
        if (context === LocationContext.Pickup) {
            dispatch(setPickupLocation(updatedLocation));
        } else {
            dispatch(setDeliveryLocation(updatedLocation));
        }
    }

    function updateAddress(addressUpdates: Partial<Address>) {
        const newLocation = {
            ...location,
            address: {
                ...location.address,
                ...addressUpdates,
            },
        };

        updateLocation(newLocation);
    }

    const { addressOptions } = useAddressOptions(location, updateAddress);

    function setBusinessName(businessName: string) {
        updateLocation({
            ...location,
            businessName,
        });
    }

    function setNotes(notes: string) {
        updateLocation({
            ...location,
            notes,
        });
    }

    function setHoursOpenFrom(openFrom: string) {
        updateLocation({
            ...location,
            hours: {
                ...location.hours,
                openFrom,
            },
        });
    }

    function setHoursOpenUntil(openUntil: string) {
        updateLocation({
            ...location,
            hours: {
                ...location.hours,
                openUntil,
            },
        });
    }

    useEffect(
        function () {
            if (loading) {
                return;
            }
            if (selectedQuote === undefined) {
                // TODO: Some sort of function for generating these
                console.warn('No quote. Should not be here');
                navigate(
                    `/book/quotes?shipmentId=${props.shipment.shipmentId}`
                );
            }
        },
        [selectedQuote, loading]
    );

    const residentialConfirm = useResidentialConfirmModal()
    const addedResidentialAlert = useAlertModal()

    // TODO: Second param
    const locationErrors = errorMessagesForLocation(location, false);

    const contactErrors = errorMessagesForContact(
        contact,
        EmailRequired.EmailNotRequired,
        comparisonContacts
    );
    const contactWarnings = warningMessagesForContact(
        contact,
        location,
        context
    );

    const pickupHoursWarnings = warningMessageForPickupHours({
        openFrom: location.hours.openFrom,
        openUntil: location.hours.openUntil,
    });

    const notesErrorMessage = errorMessageForNotes(
        location.notes,
        location.accessorials,
        location.locationType,
        props.context,
        props.onModify
    );

    const boothErrorMessage = errorMessageForBoothNumber(
        boothNumber,
        location.locationType
    );

    const deadlineDate = useSelector((s: RootState) =>
        isPickup
            ? s.bookShipment.shipment.pickupDeadline
            : s.bookShipment.shipment.deliveryDeadline
    );

    const hasDeadline = useSelector((s: RootState) =>
        isPickup
            ? s.bookShipment.hasPickupDeadline
            : s.bookShipment.hasDeliveryDeadline
    );

    function handleHasDeadlineChange(value: boolean) {
        if (isPickup) {
            dispatch(setHasPickupDeadline(value));
            return;
        }
        dispatch(setHasDeliveryDeadline(value));
    }

    function handleDeadlineDateChange(value: moment.Moment | undefined) {
        const date = value?.format('YYYY-MM-DD');
        if (isPickup) {
            dispatch(setPickupDeadline(date));
            return;
        }
        dispatch(setDeliveryDeadline(date));
    }

    const deadlineErrors = errorMessageForDeadline(
        shipment.pickupDate,
        hasDeadline,
        deadlineDate
    );

    const hasDeadlineErrors = errorMessageForHasDeadline(
        location.locationType,
        hasDeadline
    );

    const referenceNumberValidation = useValidateReferenceNumber({
        locationContext: props.context,
        originalReferenceNumber: undefined,
    });

    function isScreenCompletelyEntered() {
        const anyErrorMessages = [
            locationErrors.businessName,
            locationErrors.address.addressLine,
            locationErrors.hours,
            contactErrors.emailAddress,
            contactErrors.contactName,
            contactErrors.phoneNumber,
            notesErrorMessage,
            boothErrorMessage,
            deadlineErrors,
            hasDeadlineErrors,
        ].some((e) => e !== undefined);

        return !anyErrorMessages;
    }

    async function nextIsValid() {
        console.log(`&&&& nextIsValid`)
        setForceValidation(true);

        if (!isScreenCompletelyEntered()) {
            return false;
        }

        // If this isn't marked as residential - check if it is residential according to FedEx
        console.log(`&&&& checking location type : ${location.locationType}`)
        if (location.locationType !== LocationType.Residential) {
            console.log(`&&&& not residential - checking if it is residential`)
            const isResidentialResult = await getStartedApi.postCheckIfAddressIsResidential({
                checkIfAddressIsResidentialRequest: {
                    // isScreenCompletelyEntered should have done sufficient verifications to make this cast safe
                    address: location.address as Address
                }
            })
            console.log(`&&&& not residential - checking if it is residential - result`, { isResidentialResult })

            if (isResidentialResult.isResidential) {
                console.log(`&&&& not residential - checking if it is residential - showing confirmation`, { isResidentialResult })
                const residentialConfirmResult = await residentialConfirm();
                console.log(`&&&& not residential - checking if it is residential - confirmation result`, { isResidentialResult, residentialConfirmResult })
                if (residentialConfirmResult == ConfirmResidentialResult.AddResidentialToQuote) {
                    console.log(`&&&& not residential - going to modify quotes`)
                    dispatch(loadFromShipmentToModify({ shipment, savedLocations }));
                    if (props.context === LocationContext.Pickup) {
                        const updatedPickupLocation: AddLocationLocationType = {
                            ...shipment.pickupLocation,
                            locationType: LocationType.Residential
                        }
                        dispatch(setPickupLocation(updatedPickupLocation))
                    }
                    if (props.context === LocationContext.Delivery) {
                        const updatedDeliveryLocation: AddLocationLocationType = {
                            ...shipment.deliveryLocation,
                            locationType: LocationType.Residential
                        }
                        dispatch(setDeliveryLocation(updatedDeliveryLocation))
                    }

                    await shipmentsService.deleteQuotedShipment(
                        shipment.shipmentId,
                        'Modified to add residential'
                    );

                    navigate('/book/details');
                    addedResidentialAlert("Details Updated", "Residential has now been added. Please confirm and re-request quotes.")
                    return false
                } else {
                    // Allow to proceed
                    console.log(`&&&& not residential - customer accepted risk`)
                }
            }
        }

        // TODO: Validate that we can make this type cast
        const request: PostShipmentsSetAddressAndContactRequest = {
            setAddressAndContactRequest: {
                shipmentId: shipmentId,
                context: context,
                // isScreenCompletelyEntered should have done sufficient verifications to make these casts safe
                location: location as Location,
                contact: contact as Contact,
                referenceNumber: referenceNumber,
                boothNumber: boothNumber,
                deadline: deadlineDate,
                markLocationAsFavourite: props.markLocationAsFavourite,
                // We only want to do this for the pickup location in getting started
                markLocationAsDefault: false,
            },
        };

        await getStartedApi.postShipmentsSetAddressAndContact(request);
        props.onComplete();
        return true;
    }
    /////////////////////////////////////////////////////////

    // Guard. Should have already redirected above
    if (selectedQuote === undefined) {
        console.warn('No selected quote, rendering nothing');
        return <></>;
    }

    function selectSavedContact(savedContactId: UUID) {
        const contact = matchingSavedLocation?.contacts.find(
            (sc) => sc.savedContactId === savedContactId
        );

        if (!contact) {
            throw new Error('Contact not found');
        }
        updateContact(contact.contact);

        setSavedContactId(savedContactId);
    }

    console.log('AddressScreenComponent');

    let i = 1;

    if (loading) {
        return <LoadingShipment />;
    }

    return (
        <div id={props.context + '-address'}>
            <BookShipmentScreenLayout
                pageHeading={displayName + ' Address'}
                pageSubheading={
                    <>
                        <div>
                            Please fill in the remaining information below. Take
                            care to be accurate, as this information will be
                            used to generate your documents.
                        </div>
                    </>
                }
            >
                <Spacer height={32} />
                <BookShipmentSmallSection
                    header={
                        <>
                            <>Address Details</>
                            <AddressQuestionBubble
                                displayName={displayName}
                                context={props.context}
                                onModify={props.onModify}
                                location={location}
                            />
                        </>
                    }
                    description={
                        props.context === LocationContext.Pickup
                            ? 'Finish details of the address where the shipment will be picked up'
                            : 'Finish details of the address where the shipment will be delivered'
                    }
                    index={i++}
                >
                    <Box
                        width={608}
                        style={{ paddingTop: '32px', paddingBottom: '32px' }}
                    >
                        <AddressInput
                            enabled={true}
                            businessName={location.businessName || ''}
                            businessNameChanged={setBusinessName}
                            locationType={location.locationType}
                            address={location.address}
                            addressChanged={updateAddress}
                            forceValidation={forceValidation}
                            businessNameErrorMessages={
                                locationErrors.businessName
                            }
                            addressErrorMessages={locationErrors.address}
                            addressOptions={addressOptions}
                            displayName={displayName}
                            allowCityAndPostalCodeEditing={false}
                            warnAboutPotentialBusinessNameErrors={true}
                        />
                        <>
                            Dock Hours <DockHoursQuestionBubble />
                        </>
                        <DockHoursInput
                            openFrom={location.hours?.openFrom}
                            openUntil={location.hours?.openUntil}
                            setOpenFrom={setHoursOpenFrom}
                            setOpenUntil={setHoursOpenUntil}
                            errorMessage={locationErrors.hours}
                            forceValidation={forceValidation}
                            warningMessage={pickupHoursWarnings}
                        />
                        {props.isSameDayPickup && (
                            <Legalese style={{ maxWidth: '400px' }}>
                                Note: For same day pickups, typically a carrier
                                needs at least 3 hours notice to be able to pick
                                up a shipment. If the carrier is unable to pick
                                it up, they will pick it up on the next business
                                day.
                            </Legalese>
                        )}
                    </Box>
                    <Spacer height={16} />
                    <HorizontalStack>
                        <Switch
                            on={props.markLocationAsFavourite}
                            onChange={props.onMarkLocationAsFavourite}
                        />
                        <Spacer width={8} />
                        <Stack align="left">
                            <Label>
                                Make this a favourite in your address book?{' '}
                                <FavouriteStar />
                            </Label>
                            <Legalese>
                                Make it a favourite if you plan to ship to/from
                                this location frequently
                            </Legalese>
                        </Stack>
                    </HorizontalStack>
                </BookShipmentSmallSection>

                <Spacer height={32} />

                <BookShipmentSmallSection
                    header={
                        <>
                            <>Contact Details</>
                            <ContactQuestionBubble
                                displayName={displayName}
                                context={props.context}
                            />
                        </>
                    }
                    description={`Enter the ${props.context === LocationContext.Pickup
                        ? `shipper's`
                        : `receiver's`
                        } contact details`}
                    index={i++}
                >
                    {savedContactId &&
                        matchingSavedLocation &&
                        matchingSavedLocation.contacts.length > 1 && (
                            <>
                                <Spacer height={16} />

                                <SavedContactsDropdown
                                    savedContactId={savedContactId}
                                    onSetSavedContactId={selectSavedContact}
                                    savedContacts={
                                        matchingSavedLocation.contacts
                                    }
                                />

                                <Spacer height={32} />
                            </>
                        )}
                    <Box width={608} style={{ paddingTop: '32px' }}>
                        <ContactInput
                            contact={contact}
                            contactChanged={updateContact}
                            forceValidation={forceValidation}
                            errorMessages={contactErrors}
                            warningMessages={contactWarnings}
                        ></ContactInput>
                    </Box>
                </BookShipmentSmallSection>

                <Spacer height={32} />
                <BookShipmentSmallSection
                    header={
                        <>
                            {props.context == LocationContext.Pickup &&
                                `Pickup Reference Number`}
                            {props.context == LocationContext.Delivery &&
                                `Delivery Reference Number`}
                            <Optional>Optional</Optional>
                            <ReferenceNumberQuestionBubble
                                displayName={displayName}
                            />
                        </>
                    }
                    description={`If you have a reference number for the ${props.context === LocationContext.Pickup
                        ? `shipper's`
                        : `receiver's`
                        } system, enter it here.`}
                    index={i++}
                >
                    <Box width={608} style={{ paddingTop: '24px' }}>
                        <ReferenceNumberInput
                            validation={referenceNumberValidation}
                            label="Enter reference number"
                            width={520}
                            value={referenceNumber}
                            onChange={(newValue: string) =>
                                setReferenceNumber(newValue)
                            }
                            maxLength={256}
                        />
                    </Box>
                </BookShipmentSmallSection>
                {location.locationType === LocationType.TradeShow && (
                    <>
                        <Spacer height={32} />
                        <BookShipmentSmallSection
                            header={
                                <>
                                    {props.context == LocationContext.Pickup &&
                                        `Tradeshow Pickup Booth Number`}
                                    {props.context ==
                                        LocationContext.Delivery &&
                                        `Tradeshow Delivery Booth Number`}
                                    <BoothNumberQuestionBubble
                                        displayName={displayName}
                                    />
                                </>
                            }
                            description={`Booth number is required when dealing with trade show ${pluralDisplayName}`}
                            index={i++}
                        >
                            <Box width={608} style={{ paddingTop: '24px' }}>
                                <Input
                                    label="Enter booth number"
                                    type="text"
                                    width={520}
                                    value={boothNumber}
                                    onChange={(newValue: string) =>
                                        setBoothNumber(newValue)
                                    }
                                    maxLength={256}
                                    errorMessage={boothErrorMessage}
                                    forceValidation={forceValidation}
                                />
                            </Box>
                        </BookShipmentSmallSection>
                    </>
                )}
                {location.locationType === LocationType.GroceryWarehouse && (
                    <DeadlineSection
                        titleContext={displayName}
                        pickupDate={shipment.pickupDate}
                        deadlineDate={deadlineDate}
                        onDeadlineDateChange={handleDeadlineDateChange}
                        deadlineErrors={deadlineErrors}
                        hasDeadline={hasDeadline}
                        onHasDeadlineChange={handleHasDeadlineChange}
                        hasDeadlineErrors={hasDeadlineErrors}
                        forceValidation={forceValidation}
                    />
                )}
                <Spacer height={32} />
                <BookShipmentSmallSection
                    header={
                        <>
                            Notes<Optional>Optional</Optional>
                            <NotesQuestionBubble displayName={displayName} />
                        </>
                    }
                    description="Enter any extra information you want the carrier to know about this location"
                    index={i++}
                >
                    <Box width={608} style={{ paddingTop: '24px' }}>
                        <Input
                            label="Enter notes"
                            placeholder="eg. Blue dock door, or ring bell"
                            type="text"
                            width={520}
                            value={location.notes}
                            onChange={(newValue: string) => setNotes(newValue)}
                            errorMessage={notesErrorMessage}
                            forceValidation={forceValidation}
                        />
                    </Box>
                </BookShipmentSmallSection>

                <BookShipmentNavigationFooter2
                    next="Next"
                    onPrevious={props.onPrevious}
                    nextUrl={`/book/${props.nextIdentifier}?shipmentId=${shipmentId}`}
                    nextIsValid={nextIsValid}
                    nextIsEnabled={() => true}
                    nextIdentifier={props.nextIdentifier}
                    hidePreviousButton={false}
                />
            </BookShipmentScreenLayout>
        </div>
    );
};
export default AddressScreenComponent;
