import { isProduction } from 'environment';

// Note, there is code higher up that filters out SingleShipment, Personal and Logistic companies from this
function calculateConversionValueFromShipmentFrequency(
    shipmentFrequency: string | undefined
): number {
    switch (shipmentFrequency) {
        case 'Daily':
            return 1000.0;
        case 'Weekly':
            return 200.0;
        case 'Occasional':
            return 25.0;
        default:
            return 25.0;
    }
}

export function useGoogleAdsConversions() {
    function generateConversionFunction(tag: string) {
        return function (shipmentFrequency: string | undefined) {
            // Event snippet for Request quote conversion page
            if (!isProduction()) {
                return;
            }

            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                gtag('event', 'conversion', {
                    send_to: tag,
                    value: calculateConversionValueFromShipmentFrequency(
                        shipmentFrequency
                    ),
                    currency: 'CAD',
                });
            } catch (e) {
                console.warn('Error in google tag', { e });
            }
        };
    }

    const onAccountCreated = generateConversionFunction(
        'AW-478855751/gb1rCJLs46cYEMeEq-QB'
    );
    const onShipmentBooked = generateConversionFunction(
        'AW-478855751/PxB3COXt6ukBEMeEq-QB'
    );

    return { onAccountCreated, onShipmentBooked };
}
